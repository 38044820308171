import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Box, Grid } from '@mui/material'
import { ModelAutocompleteField, TextField, ModelMenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('user.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'customerinfo.client_number'} label={I18n.t('user.number')} />
    </Grid>
    <Grid item xs>
      <TextField name={'auth.gsm'} label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
    <Grid item xs={3}>
      <TextField name='auth.email' label={I18n.t('email.label', { count: 1 })} />
    </Grid>
    <Grid item xs={1}>
      <ModelMenuSelectorField
        name={'auth.application'}
        label={I18n.t('application.label', { count: 1 })}
        model_name={'application'}
        loadOnMount
        searchMode='search'
        labelKeys={['name']}
        config={{ sort: ['display_index ASC'] }}
        noneLabel={I18n.t('all_secondary')}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'customerinfo.name'}
        label={I18n.t('designation')}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'info.last_name'}
        label={I18n.t('name.label', { count: 1 })}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'info.first_name'}
        label={I18n.t('name.first_name', { count: 1 })}
      />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} >
    <Grid item xs={8} lg={4}>
      <ModelMenuSelectorField
        name='commercial_formula'
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name='commercialformula'
        labelKeys={['name']}
        searchMode={'search'}
        loadOnMount
        multiple
        clearOnEmpty
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Box sx={{ boxShadow: 6, borderRadius: 1, p: 2 }}>
    <Grid container rowSpacing={3}>
      <Title />
      <Row1 />
      <Row2 />
    </Grid>
  </Box>

export default React.memo(SearchForm)

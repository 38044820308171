import FormatUtils from '@front/squirtle/utils/format'

class Callbacks {
  static formToRecordHandler(user_id) {
    return function formToRecord(values, extra, meta, { suspended, ...state }) {
      return {
        ...values,
        staffinfo: { app_version: null },
        type: 'staff',
        status: FormatUtils.parseStatus((_.get(values, 'status')), _.pick(suspended, ['suspendedUntil', 'suspendedSince', 'motive']))
      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record,
        status: FormatUtils.getStatus(_.pick(_.get(record, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'auth.username')
      }
    }
  }

  static onSubmitSuccessHandler(openDialog, user_id) {
    return async function onSubmitSuccess(result, values, extra, meta) {
      if (!!user_id) return
      return openDialog('reset_password')
    }
  }

  static onConfirmPasswordHandler(userModify, user_id) {
    return async function onConfirmPassword(values, extra, meta, state) {
      const { password } = values || {}
      await userModify({ filter: { user_id }, updates: { auth: { password }, type: 'staff' } })
    }
  }
}

export default Callbacks

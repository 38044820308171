import _ from "lodash"

class Callbacks {
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return _.merge({}, values, { staffinfo: { '!': null } })
    }
  }
}

export default Callbacks

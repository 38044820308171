
import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'
import Aux from '../../Aux'


const withContainer = Component => (props) => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const [supported_languages] = hooks.useSetting('supported_languages')
  const [status] = hooks.useFieldValues(['status'])
  const [, { openDialog }] = hooks.useDialogs()
  const suspendedStateName = 'suspended'
  const [, setSuspendedState] = hooks.useFormState(suspendedStateName)
  const [user] = hooks.useModel('user', [record_id], { single: true, populate: ['auth'] })
  const [modifyUser] = hooks.useModelFunction('user', 'modify')

  const formattedSuspension = Aux.parseStatus(user)
  const statusName = FormatUtils.getStatus(_.pick(_.get(user, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
  const displayRemoveSuspend = !!formattedSuspension && statusName === 'active'

  const initialSuspendedValues = {
    name: statusName,
    motive: _.get(user, 'motive'),
    suspendedSince: _.get(user, 'suspendedSince'),
    suspendedUntil: _.get(user, 'suspendedUntil')
  }

  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])
  const onClickRemoveSuspend = useCallback(Callbacks.onClickRemoveSuspendHandler(openDialog), [openDialog])
  const onConfirmRemoveSuspend = useCallback(Callbacks.onConfirmRemoveSuspendHandler(record_id, modifyUser), [record_id, modifyUser])
  const verifyUsername = useCallback(hooks.useRecordExist('user', 'auth.username'), [])
  const verifyEmail = useCallback(hooks.useRecordExist('user', 'auth.email', { staffinfo: { '!': null } }), [])

  const color = useMemo(() => {
    if (!!_.includes(['inactive', 'archived', 'suspended'], status)) return 'orange'
    else if (status === 'active') return 'green'
    else return 'red'
  }, [status])

  const mergedProps = {
    supported_languages,
    color,
    isManagingRecord,
    formattedSuspension,
    statusChange,
    suspendedStateName,
    initialSuspendedValues,
    displayRemoveSuspend,
    onClickRemoveSuspend,
    onConfirmRemoveSuspend,
    verifyUsername,
    verifyEmail,
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer

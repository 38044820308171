import React from 'react'
import { I18n } from '@front/volcanion/'

import { TextField, ModelMenuSelectorField, MenuSelectorField, ConfirmationDialog } from '@front/squirtle/'
import { Typography, Grid, Box, StepLabel, StepContent, Button } from '@mui/material'

import FormatUtils from '@front/squirtle/utils/format'
import SuspensionDialog from '@abra/elements/Popup/Suspension'

const Row1 = ({ verifyUsername }) =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <TextField name={'auth.username'} label={I18n.t('user.username')} required afterUserChange={verifyUsername} debounceTime={250} />
    </Grid>
    <Grid item xs>
      <TextField name={'info.last_name'} label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs>
      <TextField name={'info.first_name'} label={I18n.t('name.first_name', { count: 1 })} required />
    </Grid>
  </Grid>

const Row2 = ({
  supported_languages,
  isManagingRecord,
  statusChange,
  color,
  verifyEmail,
  ...props
}) =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={3}>
      <ModelMenuSelectorField
        name='auth.role'
        label={I18n.t('user.role')}
        model_name='role'
        labelKeys={['name']}
        loadOnMount
        searchMode={'search'}
        required
      />
    </Grid>
    <Grid item xs={3}>
      <TextField name={'auth.email'} label={I18n.t('email.label', { count: 1 })} afterUserChange={verifyEmail} debounceTime={250} />
    </Grid>
    <Grid item xs={3}>
      <MenuSelectorField
        name={'info.preferred_language'}
        label={I18n.t('lang', { count: 1 })}
        options={_.map(supported_languages, (lang, index) => ({
          label: <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Box sx={{ mr: 1 }} > {FormatUtils.getFlagIcon(lang)} </Box>
            <Typography> {_.upperCase(lang)} </Typography>
          </Box>,
          value: lang
        }))}
        selectFirst
        required
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: <Box sx={{ color: 'green', fontWeight: 500 }}> {I18n.t('status.active')}  </Box>, value: 'active' },
          { label: <Box sx={{ color: 'red', fontWeight: 500 }}> {I18n.t('status.deleted')}  </Box>, value: 'deleted', disabled: !isManagingRecord },
          { label: <Box sx={{ color: 'orange', fontWeight: 500 }}> {I18n.t('status.suspended')}  </Box>, value: 'suspended', disabled: !isManagingRecord },
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: color, fontWeight: 600 }
        }}
        selectFirst
        required
        afterChange={statusChange}
      />
    </Grid>
  </Grid >


const PrimarySection = ({
  suspendedStateName,
  initialSuspendedValues,
  onConfirmRemoveSuspend,
  formattedSuspension,
  displayRemoveSuspend,
  onClickRemoveSuspend,
  ...props
}) => <>
    <SuspensionDialog
      form_name='staff'
      enable_motive={true}
      suspendedStateName={suspendedStateName}
      initialValues={initialSuspendedValues}
    />
    <ConfirmationDialog name={'remove_staff_suspend'} onConfirm={onConfirmRemoveSuspend} />
    <StepLabel>
      <Grid container>
        <Grid item xs>
          <Typography sx={{ fontSize: 'h6.fontSize', color: 'text.title' }}>{I18n.t('info.general')}</Typography>
        </Grid>
        <Grid item xs>
          <Typography sx={{ ml: 1, mt: '5px', fontSize: 'subtitle2.fontSize', color: 'red' }} > {formattedSuspension}</Typography>
        </Grid>
        <Grid item xs hidden={!displayRemoveSuspend}>
          <Button size='small' variant='outlined' onClick={onClickRemoveSuspend}>
            {I18n.t('suspend.stop.suspend')}
          </Button>
        </Grid>
      </Grid>
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 5, boxShadow: 6, borderRadius: 3 }}>
        <Grid container rowSpacing={3}>
          <Row1 {...props} />
          <Row2 {...props} />
        </Grid>
      </Box>
    </StepContent>
  </>

export default React.memo(PrimarySection)
